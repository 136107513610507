<template>
    <div class="exposureDetails">
        <van-nav-bar title="曝光详情" left-arrow :safe-area-inset-top="true" @click-left="onClickLeft" :fixed="true"
            :border="false" :placeholder="true" v-show="is_navbar === 'true'" />
        <div class="exposureDetailsBody">
            <div class="exposureDetailsTitle">
                <div class="exposureDetailsTitleText">黑名单曝光期</div>
                <div class="exposureDetailsTitleContent">
                    超过公示期7天未配合平台退号/退款，订单将进入警方立案程序
                </div>
            </div>
            <div class="contain">
                <div class="title">账号找回卖家信息</div>
                <div class="exposureDetailsBodyCenter">
                    <div class="filedItem">
                        <div class="filedItemLeft">找回人姓名</div>
                        <div class="filedItemRight">
                            {{ userInformation.real_name }}
                        </div>
                    </div>
                    <div class="filedItem">
                        <div class="filedItemLeft">手机号</div>
                        <div class="filedItemRight">{{ userInformation.mobile }}</div>
                    </div>
                    <div class="filedItem">
                        <div class="filedItemLeft">身份证号</div>
                        <div class="filedItemRight">
                            {{ userInformation.identity_card_number }}
                        </div>
                    </div>
                    <div class="filedItem">
                        <div class="filedItemLeft">线下地址</div>
                        <div class="filedItemRight">{{ userInformation.address }}</div>
                    </div>
                </div>
                <div class="exposureDetailsBodyCenter">
                    <div class="title">游戏信息</div>
                    <div class="filedItem">
                        <div class="filedItemLeft">游戏账号</div>
                        <div class="filedItemRight">{{ userInformation.account }}</div>
                    </div>
                    <div class="filedItem">
                        <div class="filedItemLeft">游戏名称</div>
                        <div class="filedItemRight">
                            {{ userInformation.game_name }}
                        </div>
                    </div>
                    <div class="filedItem">
                        <div class="filedItemLeft">订单金额</div>
                        <div class="filedItemRight">{{ userInformation.order_amount }}元</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getMaterialDetail } from "@/api/security";
export default {
    name: "newExposureDetails",
    data() {
        return {
            userInformation: {},
            is_navbar: "true",
        };
    },
    mounted() {
        let config = {
            id: this.$route.query.id,
        };
        this.requestGetMaterialDetail(config);
        if (this.$route.query.is_navbar) {
            this.is_navbar = this.$route.query.is_navbar || "true";
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        },
        async requestGetMaterialDetail(config) {
            this.loading = true;
            try {
                const result = await getMaterialDetail(config);
                if (result.code === 20000 || result.status_code === 1000) {
                    this.userInformation = result.data;
                } else {
                    this.$toast(result.message);
                }
            } catch (error) {
                this.$toast("请求失败");
            }
        },
    },
};
</script>

<style lang="less" scoped>
.exposureDetails {

    //   width: 100%;
      min-height: 100vh;
      background: #F6F7F8;
    .exposureDetailsBody {
        display: flex;
        flex-direction: column;
        align-items: center;

        .exposureDetailsTitle {
            width: 375px;
            height: 65px;
            background: #FFF7F1;
            padding: 10px 56px 10px 10px;
            box-sizing: border-box;

            .exposureDetailsTitleText {
                width: 93px;
                height: 24px;
                background: url('../../../static/images/baoguangBg.png') no-repeat center center;
                background-size: 100%;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                font-size: 13px;
                color: #FFFFFF;
                line-height: 24px;
                text-align: center;
                font-style: normal;
            }

            .exposureDetailsTitleContent {
                margin-top: 5px;
                width: 309px;
                height: 16px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                font-size: 11px;
                color: #FF7A3E;
                line-height: 16px;
                text-align: left;
                font-style: normal;
            }
        }

        .contain {
            // padding: 10px;
            // width: 375px;
            // height: 659px;
            // background: #F6F7F8;
            // box-sizing: border-box;

            .title {
                margin-top: 10px;
                margin-bottom: 10px;
                width: 128px;
                height: 22px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                font-size: 16px;
                color: #191919;
                line-height: 22px;
                text-align: left;
                font-style: normal;
            }

            .exposureDetailsBodyCenter {
                // width: 335px;


                .filedItem {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    padding: 17px 20px 15px 15px;
                    width: 355px;
                    height: 52px;
                    // border-bottom: 1px solid #f2f2f2;
                    background: #fff;
                    border-radius: 8px;
                    align-items: center;
                    box-sizing: border-box;

                    .filedItemLeft {
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 600;
                        font-size: 14px;
                        color: #191919;
                        line-height: 20px;
                        text-align: left;
                        font-style: normal;
                    }

                    .filedItemRight {
                        // width: 42px;
                        height: 20px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;
                        line-height: 20px;
                        text-align: end;
                        font-style: normal;
                    }
                }
            }
        }
    }
}

::v-deep .van-nav-bar .van-icon {
    color: black;
}
</style>